import https from "./https";
import store from "@/store";

const serverLog = {
  getServerLogs(params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams(params);

    return https.get(
      `/admin/organizations/${organization.id}/server-logs?${searchParams}`
    );
  },
  getServerLogCategories() {
    const organization = store.state.general.organization;

    return https.get(
      `/admin/organizations/${organization.id}/server-logs/categories`
    );
  }
};

export default serverLog;
